const memory = {};

export default function useMemory () {
  function getFromMemory (key) {
    return memory[`${key}`];
  }

  function setToMemory (key, value) {
    memory[`${key}`] = value;
  }

  return {
    getFromMemory,
    setToMemory,
  };
}
