import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Command, oneClickAdapter } from "../../../lib";
import "./OneClickWidget.css";

function OneClickWidget ({ onClose, onReload }) {
  const containerRef = React.useRef(null);
  const rootSizeRef = React.useRef(null);

  useEffect(() => {
    if (!containerRef.current) {
      return null;
    }

    const root = document.getElementById("root");
    const { offsetWidth, offsetHeight } = root;
    rootSizeRef.current = { offsetWidth, offsetHeight };

    root.classList.add("scrollable");

    const onReloadGame = () => {
      oneClickAdapter.closeOneClick();

      const root = document.getElementById("root");
      root.style.width = rootSizeRef.current.offsetWidth;
      root.style.height = rootSizeRef.current.offsetHeight;

      onReload();
      onClose();
    };

    const onOpenCashier = () => {
      oneClickAdapter.closeOneClick();
      Command.openCashier();
      onClose();
    };

    const onCloseWidget = () => {
      oneClickAdapter.closeOneClick();
      onClose();
    };

    (async () => {
      try {
        await oneClickAdapter.openOneClick({
          container: containerRef.current,
          onCloseWidget,
          onReloadGame,
          onOpenCashier,
        });
      } catch (e) {
        onOpenCashier();
      }
    })();

    return () => {
      const root = document.getElementById("root");
      root.classList.remove("scrollable");

      oneClickAdapter.closeOneClick();
    };
  }, [containerRef, onClose, onReload]);

  return (
    <div className="l6r__1click-widget">
      <div className="l6r__1click-container" ref={containerRef} />
    </div>
  );
}

OneClickWidget.propTypes = {
  onClose: PropTypes.func.isRequired,
  onReload: PropTypes.func.isRequired,
};

export default OneClickWidget;
