import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link, Icon } from "../../ui";
import useMemory from "../../hooks/useMemory";
import "./AddToFavoritesButton.css";

function AddToFavoritesButton ({
  gameOptions,
  onAddToFavorites,
  onRemoveFromFavorites,
}) {
  const [ favorit, setFavorit ] = useState(false);
  const { getFromMemory, setToMemory } = useMemory();
  const isActiveRef = useRef(true);

  useEffect(() => {
    const valueFromMemory = getFromMemory("favorit");

    if (valueFromMemory === undefined) {
      setFavorit(gameOptions.isInFavorites());
    } else {
      setFavorit(valueFromMemory);
    }
  }, []);

  const getIcon = () => {
    const { HeartOutline, Heart } = Icon;

    return favorit ? Heart : HeartOutline;
  };

  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();

    if (!isActiveRef.current) {
      return;
    }

    if (favorit) {
      setToMemory("favorit", false);
      onRemoveFromFavorites();
      setFavorit(false);
    } else {
      setToMemory("favorit", true);
      onAddToFavorites();
      setFavorit(true);
    }

    isActiveRef.current = false;

    setTimeout(() => {
      isActiveRef.current = true;
    }, 700);
  };

  return (
    <Link
      className="l6r__btn-favorites"
      icon={getIcon()}
      onClick={handleClick}
      small
    />
  );
}

AddToFavoritesButton.propTypes = {
  gameOptions: PropTypes.object.isRequired,
  onAddToFavorites: PropTypes.func.isRequired,
  onRemoveFromFavorites: PropTypes.func.isRequired,
};

export default AddToFavoritesButton;
