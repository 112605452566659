import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Dialog, Icon } from "../../ui";
import { Command } from "../../lib";

function NotificationMessageDialog ({ onClose, text, buttons }) {
  const { t } = useTranslation();

  const handleConfirmClick = () => {
    onClose();
  };

  const getButtons = () => {
    const actions = [];

    if (!buttons) {
      actions.push({
        onClick: handleConfirmClick,
        label: t("confirm"),
      });

      return actions;
    }

    for (const button of buttons) {
      const action = { label: button.title };

      switch (button.action) {
        case "lobby":
          action.onClick = () => {
            Command.openLobby();
          };
          break;
        case "cashier":
          action.onClick = () => {
            Command.openCashier();
          };
          break;
        case "profile":
          action.onClick = () => {
            Command.openProfile();
          };
          break;
        case "support":
          action.onClick = () => {
            Command.openSupport();
          };
          break;
        default:
          action.onClick = () => {
            onClose();
          };
      }

      actions.push(action);
    }

    return actions;
  };

  return (
    <Dialog
      className="l6r__message-dialog"
      pause={1}
      text={text}
      icon={Icon.Notice}
      buttons={getButtons()}
      toast
    />
  );
}

NotificationMessageDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  buttons: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    action: PropTypes.string,
  })),
};

export default NotificationMessageDialog;
