import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  reloadGameIframe,
  windowOrientationObserver,
  ORIENTATION_CHANGED_DIALOG_TYPE,
} from "../../lib";
import { Dialog, Icon } from "../../ui";

function OrientationWasChangedDialog ({ onClose }) {
  const { t } = useTranslation();

  useEffect(() => {
    windowOrientationObserver.subscribe(
      ORIENTATION_CHANGED_DIALOG_TYPE,
      onClose,
    );

    return () => {
      windowOrientationObserver.unsubscribe(
        ORIENTATION_CHANGED_DIALOG_TYPE,
      );
    };
  }, [onClose]);

  const handleConfirmClick = () => {
    reloadGameIframe();
    onClose();
  };

  const handleCancelClick = () => {
    onClose();
  };

  return (
    <Dialog
      title={t("orientation_was_changed.title")}
      text={t("orientation_was_changed.text")}
      confirmLabel={t("confirm")}
      cancelLabel={t("cancel")}
      onConfirmClick={handleConfirmClick}
      onCancelClick={handleCancelClick}
      icon={Icon.Warning}
      modal
    />
  );
}

OrientationWasChangedDialog.propTypes = {
  onClose: PropTypes.func,
};

export default OrientationWasChangedDialog;
