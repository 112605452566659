/* eslint-disable react/prop-types */

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import {
  FRANK,
  MRBIT,
  SLOTV,
  USER_PROGRESS_WIDGET,
} from "../../../lib";
import { wrapFetch } from "@cadolabs/brand-auther-provider";
import { Icon } from "../../../ui";
import LoadableWidget from "./LoadableWidget";
import "./UserProgress.css";

const Widget = new LoadableWidget(() => {
  return import(/* webpackPrefetch: -2 */ "@frontendwidgets/userprogress");
});

function UserProgressWidget ({ gameOptions }) {
  const { t } = useTranslation();

  const fetchData = useMemo(() => ({
    wrapper: wrapFetch,
  }), []);

  const getIconMap = () => {
    const getProgressIcon = () => {
      switch (gameOptions.getBrand()) {
        case FRANK: return Icon.FrankUserProgress;
        case SLOTV: return Icon.Slotv;
        case MRBIT: return Icon.MrbitUserProgress;
      }
    };

    const ProgressIcon = getProgressIcon();

    return ({
      LogoIcon: ({ className }) => (
        <div className={className}>
          <ProgressIcon />
        </div>
      ),
    });
  };

  const getContentMap = () => {
    let points = null;

    switch (gameOptions.getBrand()) {
      case FRANK: {
        points = "FR";
        break;
      }
      default: {
        points = t("user_progress.points");
      }
    }

    return ({
      points,
    });
  };

  const getFetchData = () => {
    return fetchData
  };

  if (!gameOptions.showUserWidgets(USER_PROGRESS_WIDGET)) {
    return null;
  }

  const cls = cx("l6r__widget", {
    "l6r__widget--user-progress": true,
  });

  return (
    <div className={cls}>
      <Widget
        iconMap={getIconMap()}
        contentMap={getContentMap()}
        origin={gameOptions.getOrigin()}
        token={gameOptions.getWidgetToken()}
        fetchData={getFetchData()}
      />
    </div>
  );
}

UserProgressWidget.propTypes = {
  gameOptions: PropTypes.object.isRequired,
};

export default React.memo(UserProgressWidget, (prevProps, nextProps) => {
  return prevProps.gameOptions === nextProps.gameOptions;
});
