import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { MENU_DIALOG_TYPE } from "../../lib";
import { Dialog } from "../../ui";
import MenuForm from "../common/MenuForm";

function MenuDialog ({
  onClose,
  gameOptions,
  onHomeClick,
  onFullscreenClick,
  onReloadClick,
  onSupportClick,
  onAddToFavorites,
  onRemoveFromFavorites,
  sendSessionForVwo,
}) {
  useEffect(() => {
    /* clickObserver.subscribe("menu-dialog", e => {
      const menuContent = document.querySelector(".l6r__section--menu");

      if (
        menuContent.contains(e.target) ||
        e.target.className === "l6r__dialog l6r__menu-form"
      ) {
        onClose(MENU_DIALOG_TYPE);
      }
    }); */

    return function clean () {
      // clickObserver.unsubscribe("menu-dialog");

      const brandBtn = document.querySelector(".l6r__brand-button");

      if (brandBtn) {
        brandBtn.classList.remove("l6r__button--expanded");
      }
    }
  }, [onClose]);

  const withClosing = func => () => {
    func();
    onClose(MENU_DIALOG_TYPE);
  };

  return (
    <Dialog className="l6r__menu-form">
      <MenuForm
        gameOptions={gameOptions}
        onHomeClick={withClosing(onHomeClick)}
        onFullscreenClick={withClosing(onFullscreenClick)}
        onReloadClick={withClosing(onReloadClick)}
        onSupportClick={withClosing(onSupportClick)}
        onAddToFavorites={onAddToFavorites}
        onRemoveFromFavorites={onRemoveFromFavorites}
        sendSessionForVwo={sendSessionForVwo}
      />
    </Dialog>
  );
}

MenuDialog.propTypes = {
  gameOptions: PropTypes.object.isRequired,
  onAddToFavorites: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onFullscreenClick: PropTypes.func.isRequired,
  onHomeClick: PropTypes.func.isRequired,
  onReloadClick: PropTypes.func.isRequired,
  onRemoveFromFavorites: PropTypes.func.isRequired,
  onSupportClick: PropTypes.func.isRequired,
  sendSessionForVwo: PropTypes.func.isRequired,
};

export default MenuDialog;
