import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { clickObserver } from "../../lib";
import { Dialog } from "../../ui";
import Limits from "../common/limits/Limits";
import "./LimitsDialog.css";

function LimitsDialog ({ limits, onClose, onClick }) {
  const { t } = useTranslation();

  useEffect(() => {
    clickObserver.subscribe("limits-dialog", () => {
      onClose();
    });

    return function clean () {
      clickObserver.unsubscribe("limits-dialog");
    }
  }, [onClose]);

  return (
    <Dialog
      className="l6r__limits-dialog"
      modal
    >
      <Dialog.Body>
        <Dialog.Header
          title={t("limits.title")}
          bordered
        />

        <Dialog.Content padded>
          <Limits limits={limits} />
        </Dialog.Content>

        <Dialog.Footer
          confirmLabel={t("confirm")}
          onConfirmClick={onClose}
          cancelLabel={t("logout")}
          onCancelClick={onClick}
          bordered
        />
      </Dialog.Body>
    </Dialog>
  );
}

LimitsDialog.propTypes = {
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  limits: PropTypes.object,
};

export default LimitsDialog;
