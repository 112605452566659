/* eslint-disable no-undef */

import React from "react";
import PropTypes from "prop-types";
import { Iframe, Loader } from "../../ui";
import { IFRAME_ID } from "../../lib";
import useDialog from "../../hooks/useDialog";
import MainMenu from "../common/MainMenu";
import "./Main.css";

function Main ({
  gameOptions,
  onBrandClick,
  onRealGameClick,
  onCashierClick,
  onCloseDialog,
  onReload,
  loading,
}) {
  const { renderDialog } = useDialog({ onReload, handleCashierClick: onCashierClick });

  return (
    <div className="l6r__main">
      <div className="l6r__section--menu">
        <MainMenu
          gameOptions={gameOptions}
          onBrandClick={onBrandClick}
          onRealGameClick={onRealGameClick}
          onCashierClick={onCashierClick}
          onCloseDialog={onCloseDialog}
          onReload={onReload}
        />
      </div>

      <div className="l6r__section--game">
        <Iframe id={IFRAME_ID} className="l6r__game-iframe" />

        <div id="modal">
          {renderDialog()}
        </div>

        <Loader loading={loading}>
          <Loader.Spinner />
        </Loader>
      </div>
    </div>
  );
}

Main.propTypes = {
  gameOptions: PropTypes.object,
  loading: PropTypes.bool,
  onBrandClick: PropTypes.func.isRequired,
  onRealGameClick: PropTypes.func.isRequired,
  onReload: PropTypes.func.isRequired,
  onCashierClick: PropTypes.func.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
};

export default Main;
