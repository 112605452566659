import { get } from "../Api";
import { decode as atob } from "base-64";

const parseToken = token => {
  if (!token) {
    return {}
  }

  return JSON.parse(atob(token.split(".")[1]));
}

const getQuery = (gameOptions, currentToken, t, page, per) => {
  const searchParams = new URLSearchParams();
  const parsedToken = parseToken(currentToken);

  searchParams.append("country_code", gameOptions.getCountryCode());
  searchParams.append("jurisdiction", gameOptions.getJurisdiction());
  searchParams.append("project_id", gameOptions.getProjectId());
  searchParams.append("device_type", gameOptions.getDeviceType());
  searchParams.append("page", page);
  searchParams.append("per", per);
  searchParams.append("cache_key", parsedToken.external_id);

  if (t) {
    searchParams.append("t", t);
  }

  return searchParams.toString();
}

export default function getGamesByCategory (
  category,
  gameOptions,
  tokenData,
  t,
  page = 1,
  per = 20,
) {
  const domain = new URL(gameOptions.getLobbyUrl());

  const { token, guestToken } = tokenData;

  const mode = token ? "auth" : "guest";
  const currentToken = token || guestToken;

  const query = getQuery(gameOptions, currentToken, t, page, per);

  const url = `${domain.origin}/srv/atlant/api/v1/project/game_sort/${category}?${query}`;

  const options = { headers: {} };
  const bautherKey = mode === "auth" ? "X-BrandAuther-AccessToken" : "X-BrandAuther-GuestToken";
  options.headers[bautherKey] = currentToken

  return get(url, options);
}
