import React, { useEffect, useRef, useCallback, Suspense } from "react";
import { I18nextProvider } from "react-i18next";
import cx from "classnames";
import {
  fetchOneClick,
  fetchVwoSession,
  addToFavorites,
  removeFromFavorites,
} from "./api";
import { Container } from "./ui";
import i18n from "./i18n";
import {
  parseUrlQuery,
  initPlatformSync,
  dialogObserver,
  fullscreenAdapter,
  oneClickAdapter,
  browserDetectorAdapter,
  gtmNotifier,
  initWebSockets,
  Command,
  ERROR_MESSAGE_DIALOG_TYPE,
  MENU_DIALOG_TYPE,
  USER_LIMITS_DIALOG_TYPE,
  WEB_DESKTOP,
} from "./lib";
import Header from "./components/sections/Header";
import Main from "./components/sections/Main";
import "./App.css";
import useGameLoader from "./hooks/useGameLoader";

function App () {
  const {
    loading,
    loadGameOptions,
    gameOptions,
    error,
  } = useGameLoader();

  const platformRef = useRef();

  const loadGame = useCallback(() => {
    const query = parseUrlQuery(window.location.search);
    query.append("apple_pay_available", applePayAvailable());

    loadGameOptions(query);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadGame();
  }, [loadGame]);

  const sendSessionForVwo = data => {
    const query = parseUrlQuery(window.location.search);
    Object.keys(data).forEach(key => {
      query.append(key, data[key]) // eslint-disable-line security/detect-object-injection
    })
    return fetchVwoSession(query);
  };

  const applePayAvailable = () => {
    try {
      return window.ApplePaySession.canMakePayments()
    } catch (e) {
      return false
    }
  };

  useEffect(() => {
    if (!gameOptions) {
      return;
    }

    const locale = gameOptions.getLocale();
    i18n.changeLanguage(locale);

    initWebSockets({ ...gameOptions, handleCashierClick, sendSessionForVwo });
  }, [i18n, gameOptions, sendSessionForVwo]);

  useEffect(() => {
    if (!gameOptions) {
      return;
    }

    async function initAndRunPlatform () {
      const platformName = gameOptions.getPlatformName();
      platformRef.current = await initPlatformSync(platformName);

      platformRef.current.setGameOptions(gameOptions);
      platformRef.current.run();
    }

    initAndRunPlatform();
  }, [gameOptions, initPlatformSync]);

  useEffect(() => {
    if (!gameOptions) {
      return;
    }

    if (gameOptions.showLimits()) {
      dialogObserver.fire(
        USER_LIMITS_DIALOG_TYPE,
        {
          limits: gameOptions.getUserLimits(),
          onClick: handleLogoutClick,
        },
      );
    }
  }, [gameOptions]);

  useEffect(() => {
    if (
      !gameOptions ||
      !gameOptions.showOneClick()
    ) {
      return;
    }

    const oneClickDataLoad = () => {
      const query = parseUrlQuery(window.location.search);
      return fetchOneClick(query);
    };

    oneClickAdapter.injectOneClick({ loadOneClick: oneClickDataLoad });
  }, [gameOptions]);

  useEffect(() => {
    if (error) {
      dialogObserver.fire(
        ERROR_MESSAGE_DIALOG_TYPE,
        { error, onClick: handleHomeClick },
      );
    }
  }, [error]);

  function handleLogoutClick () {
    Command.logout();
  };

  function handleHomeClick () {
    Command.openLobby();
  };

  const handleProfileClick = () => {
    Command.openProfile();
  };

  const handleCashierClick = () => {
    Command.openCashier();

    fullscreenAdapter.exit();
  };

  const handleCloseAnyDialog = () => {
    dialogObserver.fire(null);
  };

  const handleRealGameClick = () => {
    Command.playForReal();
    fullscreenAdapter.exit();
  };

  const handleBlockYourselfClick = () => {
    Command.blockYourself();
    fullscreenAdapter.exit();
  };

  const handleSupportClick = () => {
    Command.openSupport();
    fullscreenAdapter.exit();
  };

  const handleFullscreenClick = () => {
    fullscreenAdapter.toggle(document.getElementById("root"));
  };

  const handleReloadClick = () => {
    loadGame();
  };

  const handleAddToFavorites = () => {
    Command.addToFavorites();

    try {
      const url = new URL(gameOptions.getLobbyUrl())

      addToFavorites(url.origin, {
        project_id: gameOptions.getProjectId(),
        game_id: gameOptions.getGameId(),
      })
    } catch (_) {}

    gtmNotifier.addToFavorites({
      gameId: gameOptions.getGameId(),
      gameName: gameOptions.getGameName(),
      project: gameOptions.getBrand(),
    });
  };

  const handleRemoveFromFavorites = () => {
    Command.removeFromFavorites();

    try {
      const url = new URL(gameOptions.getLobbyUrl())

      removeFromFavorites(url.origin, {
        project_id: gameOptions.getProjectId(),
        game_id: gameOptions.getGameId(),
      })
    } catch (_) {}

    gtmNotifier.removeFromFavorites({
      gameId: gameOptions.getGameId(),
      gameName: gameOptions.getGameName(),
      project: gameOptions.getBrand(),
    })
  };

  const handleBrandClick = () => {
    dialogObserver.fire(
      MENU_DIALOG_TYPE,
      {
        gameOptions,
        sendSessionForVwo,
        onFullscreenClick: handleFullscreenClick,
        onReloadClick: handleReloadClick,
        onHomeClick: handleHomeClick,
        onSupportClick: handleSupportClick,
        onAddToFavorites: handleAddToFavorites,
        onRemoveFromFavorites: handleRemoveFromFavorites,
      },
    );

    sendSessionForVwo({ type: "activate" })
  };

  const theme = () => {
    if (!gameOptions) {
      return "default";
    }

    return gameOptions.getTheme();
  };

  const isDesktop = () => {
    if (!gameOptions) {
      return false;
    }

    return gameOptions.getDeviceType() === WEB_DESKTOP;
  };

  const hideUI = () => {
    if (!gameOptions) {
      return false;
    }

    return gameOptions.showUI() === false;
  };

  const language = () => {
    return i18n.language;
  };

  const cls = cx("l6r__main-container", {
    "l6r__main-container--desktop": isDesktop(),
    "l6r__main-container--mobile": !isDesktop(),
    "l6r__main-container--safari": browserDetectorAdapter.isSafari(),
    "l6r__main-container--hide-ui": hideUI(),
    [`l6r__main-container--${language()}`]: language(),
  });

  return (
    <Suspense>
      <I18nextProvider i18n={i18n}>
        <Container className={cls} brand={theme()}>
          <Header
            gameOptions={gameOptions}
            onProfileClick={handleProfileClick}
            onBlockYourselfClick={handleBlockYourselfClick}
          />

          <Main
            loading={loading}
            gameOptions={gameOptions}
            onBrandClick={handleBrandClick}
            onRealGameClick={handleRealGameClick}
            onCashierClick={handleCashierClick}
            onCloseDialog={handleCloseAnyDialog}
            onReload={handleReloadClick}
          />
        </Container>
      </I18nextProvider>
    </Suspense>
  );
}

/* App.propTypes = {
  gameOptions: PropTypes.shape({
    getAgeLimit: PropTypes.func,
    getBrand: PropTypes.func,
    getBrandIcon: PropTypes.func,
    getChannelId: PropTypes.func,
    getCurrency: PropTypes.func,
    getDeviceType: PropTypes.func,
    getGameId: PropTypes.func,
    getGameMode: PropTypes.func,
    getGameName: PropTypes.func,
    getGameUrl: PropTypes.func,
    getHelpPhoneNumber: PropTypes.func,
    getJurisdiction: PropTypes.func,
    getLicenseNumber: PropTypes.func,
    getLocale: PropTypes.func,
    getOneClickData: PropTypes.func,
    getParams: PropTypes.func,
    getPlatformName: PropTypes.func,
    getTheme: PropTypes.func,
    getUserLimits: PropTypes.func,
    showAgeLimit: PropTypes.func,
    showCashier: PropTypes.func,
    showHelpPhone: PropTypes.func,
    showLicense: PropTypes.func,
    showLimits: PropTypes.func,
    showOneClick: PropTypes.func,
    showPanicButton: PropTypes.func,
    showPlayForReal: PropTypes.func,
    showSwedenButtons: PropTypes.func,
    showUI: PropTypes.func,
    getLobbyUrl: PropTypes.func,
    getProjectId: PropTypes.func,
  }),
  error: PropTypes.object,
  initPlatform: PropTypes.func,
  onReload: PropTypes.func,
  loadOneClick: PropTypes.func,
  loading: PropTypes.any,
  sendSessionForVwo: PropTypes.func,
}; */

export default App;
