/* eslint-disable react/prop-types */

import React, { useState, useRef, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { TOURNAMENTS_WIDGET } from "../../../lib";
import { wrapFetch } from "@cadolabs/brand-auther-provider";
import { Icon } from "../../../ui";
import LoadableWidget from "./LoadableWidget";
import "./Tournaments.css";

const Widget = new LoadableWidget(() => {
  return import(/* webpackPrefetch: -3 */ "@frontendwidgets/tournaments");
});

function TournamentsWidget ({ gameOptions }) {
  const { t } = useTranslation();
  const [ isFaild, setIsFaild ] = useState(false);
  const faildCallbackRef = useRef(null);

  const fetchData = useMemo(() => ({
    wrapper: wrapFetch,
  }), []);

  useEffect(() => {
    faildCallbackRef.current = () => {
      setIsFaild(true);
    };

    return () => {
      faildCallbackRef.current = null;
    }
  }, [])

  const getIconMap = () => ({
    LogoIcon: ({ className }) => (
      <div className={className}>
        <Icon.Tournament />
      </div>
    ),
    ToTheLeftButtonIcon: ({ className }) => (
      <div className={className}>
        <Icon.SchevronLeft />
      </div>
    ),
    ArrowClosedIcon: ({ className }) => (
      <div className={className}>
        <Icon.DailyChallengeArrowClosedIcon />
      </div>
    ),
  });

  const getContentMap = () => {
    return {
      title: t("tournaments.title"),
      ended: t("tournaments.ended"),
      endsIn: t("tournaments.endsIn"),
      prizePool: t("tournaments.prizePool"),
      timerDays: t("tournaments.timerDays"),
      timerHours: t("tournaments.timerHours"),
      timerMinutes: t("tournaments.timerMinutes"),
      timerSeconds: t("tournaments.timerSeconds"),
      bestPlace: t("tournaments.bestPlace"),
      currentPlace: t("tournaments.currentPlace"),
    };
  };

  const getEventMap = () => {
    return ({
      whenFailInitialStateLoaded (_) {
        if (faildCallbackRef.current) {
          faildCallbackRef.current()
        }
      },
      getWidgetSettings (_) {
        return ({
          locale: gameOptions.getLocale(),
          defaultLocale: gameOptions.getLocale(),
          country: gameOptions.getCountryCode(),
          currency: gameOptions.getCurrency(),
          defaultCurrency: gameOptions.getCurrency(),
          requestProps: {
            projectId: gameOptions.getProjectId(),
            deviceType: gameOptions.getDeviceType(),
            jurisdiction: gameOptions.getJurisdiction(),
            countryCode: gameOptions.getCountryCode(),
            cacheKey: gameOptions.getChannelId(),
          },
        })
      },
    });
  };

  const getClassNameMap = () => {
    return ({
      buttonDots: "l6r__widget--tournaments-buttons-dots",
      buttonArrowLeft: "l6r__widget--tournaments-buttons-arrow-left",
      buttonArrowRight: "l6r__widget--tournaments-buttons-arrow-right",
    });
  };

  const getFetchData = () => {
    return fetchData;
  };

  if (!gameOptions.showUserWidgets(TOURNAMENTS_WIDGET)) {
    return null;
  }

  if (isFaild) {
    return null;
  }

  const cls = cx("l6r__widget", {
    "l6r__widget--tournaments": true,
  });

  return (
    <div className={cls}>
      <Widget
        iconMap={getIconMap()}
        contentMap={getContentMap()}
        classNameMap={getClassNameMap()}
        origin={gameOptions.getOrigin()}
        token={gameOptions.getWidgetToken()}
        eventMap={getEventMap()}
        fetchData={getFetchData()}
      />
    </div>
  );
}

TournamentsWidget.propTypes = {
  gameOptions: PropTypes.object.isRequired,
};

export default React.memo(TournamentsWidget, (prevProps, nextProps) => {
  return prevProps.gameOptions === nextProps.gameOptions;
});
