import { useState } from "react";
import { fetchGameOptions } from "../api";
import {
  GameOptions,
  UrlQueryValidator,
  GameOptionsValidator,
  HIDE_UI_KEY,
} from "../lib";

export default function useGameLoader () {
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(null);
  const [ gameOptions, setGameOptions] = useState(null);

  async function loadGameOptions (query) {
    try {
      setLoading(true);
      setError(null);

      validateUrlQuery(query);

      const hideUI = getHideUI(query);

      const { data } = await fetchGameOptions(query);
      validateGameOptions(data);

      const gameOptions = new GameOptions(data, hideUI)

      setDocumentTitle(gameOptions);
      setGameOptions(gameOptions);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  }

  function getHideUI (urlQuery) {
    return !!urlQuery.get(HIDE_UI_KEY);
  }

  function validateUrlQuery (urlQuery) {
    const { isValid, error } = new UrlQueryValidator().validate(urlQuery);

    if (!isValid) {
      throw error;
    }
  }

  function validateGameOptions (gameOptions) {
    const { isValid, error } = new GameOptionsValidator().validate(gameOptions);

    if (!isValid) {
      throw error;
    }
  }

  function setDocumentTitle (gameOptions) {
    document.title = gameOptions.getGameTitle();
  }

  return {
    loading,
    loadGameOptions,
    gameOptions,
    error,
  };
}
