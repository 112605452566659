/* eslint-disable react/prop-types */

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { PENDING_WITHDRAWALS_WIDGET } from "../../../../lib";
import LoadableWidget from "../LoadableWidget";
import {
  useContentMap,
  useEventMap,
  useIconMap,
  useInjectScript,
  fetchData,
} from "./pendingWithdrawalsWidgetProps"
import "./PendingWithdrawals.css";

const Widget = new LoadableWidget(() => {
  return import(/* webpackPrefetch: -3 */ "@frontendwidgets/pendingwithdrawals");
});

function PendingWithdrawalsWidget ({ gameOptions, onReload }) {
  const brand = gameOptions.getBrand()
  const iconMap = useIconMap(brand)
  const contentMap = useContentMap()
  const eventMap = useEventMap({
    locale: gameOptions.getLocale(),
    country: gameOptions.getCountryCode(),
    onReload,
  })

  useInjectScript(brand)

  if (!gameOptions.showUserWidgets(PENDING_WITHDRAWALS_WIDGET)) {
    return null;
  }

  const cls = cx("l6r__widget", {
    "l6r__widget--pending-withdrawals": true,
  });

  return (
    <div className={cls}>
      <Widget
        iconMap={iconMap}
        contentMap={contentMap}
        eventMap={eventMap}
        origin={gameOptions.getOrigin()}
        token={gameOptions.getWidgetToken()}
        fetchData={fetchData}
      />
    </div>
  );
}

PendingWithdrawalsWidget.propTypes = {
  gameOptions: PropTypes.object.isRequired,
  onReload: PropTypes.func.isRequired,
};

export default React.memo(PendingWithdrawalsWidget, (prevProps, nextProps) => {
  return prevProps.gameOptions === nextProps.gameOptions;
});
