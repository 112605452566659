/* eslint-disable react/prop-types */
/* eslint-disable max-len */

import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import {
  INSUFFICIENT_BALANCE_WIDGET,
  clickObserver,
  LOW_BALANCE_DIALOG_TYPE,
} from "../../../../lib";
import LoadableWidget from "../LoadableWidget";
import {
  useContentMap,
  useEventMap,
  useIconMap,
  useInjectScript,
  classNameMap,
  fetchData,
} from "./insufficientBalanceWidgetProps"
import {
  useContentMap as useContentMapPendingWithdrawals,
  useEventMap as useEventMapPendingWithdrawals,
  useIconMap as useIconMapPendingWithdrawals,
  useInjectScript as useInjectScriptPendingWithdrawals,
  fetchData as fetchDataPendingWithdrawals,
} from "../PendingWithdrawals/pendingWithdrawalsWidgetProps"

const Widget = new LoadableWidget(() => {
  return import(/* webpackPrefetch: -3 */ "@frontendwidgets/insufficientbalance");
});

const WidgetPendingWithdrawals = new LoadableWidget(() => {
  return import(/* webpackPrefetch: -3 */ "@frontendwidgets/pendingwithdrawals");
});

function InsufficientBalanceWidget ({
  balance,
  currency,
  spins_count: spinsCount,
  gameOptions,
  onReload,
  onClose,
  handleCashierClick,
}) {
  const brand = gameOptions.getBrand()
  const iconMap = useIconMap(brand)
  const iconMapPendingWithdrawals = useIconMapPendingWithdrawals(brand)
  const contentMap = useContentMap({
    spinsCount,
    currency,
    rightBalance: Number.isNaN(Number.parseFloat(balance)) ? 0 : parseFloat(balance).toFixed(2),
  })
  const contentMapPendingWithdrawals = useContentMapPendingWithdrawals()
  const eventMap = useEventMap({
    handleCashierClick,
    onClose,
  })
  const eventMapPendingWithdrawals = useEventMapPendingWithdrawals({
    locale: gameOptions.getLocale(),
    country: gameOptions.getCountryCode(),
    onReload,
  })

  useInjectScript(brand)
  useInjectScriptPendingWithdrawals(brand)

  useEffect(() => {
    clickObserver.subscribe("low-balance-dialog", e => {
      if (
        e.target.className &&
        typeof e.target.className === "string" &&
        e.target.className.includes("insufficient-balance-main-container")
      ) {
        onClose(LOW_BALANCE_DIALOG_TYPE);
      }
    });

    return function clean () {
      clickObserver.unsubscribe("low-balance-dialog");
    }
  }, [onClose]);

  const eventMapPendingWithdrawalsPatched = useMemo(() => ({
    ...eventMapPendingWithdrawals,
    getWidgetSettings: () => ({
      ...eventMapPendingWithdrawals.getWidgetSettings(),
      theme: "dark",
      variation: "variation1",
      withoutPreloader: true,
      withoutToggleBody: true,
      withoutMainIfOpenPopup: true,
    }),
    cancelPopupClose: () => {
      eventMapPendingWithdrawals.cancelPopupClose?.();
      onClose();
    },
    errorPopupButtonClose: () => {
      eventMapPendingWithdrawals.errorPopupButtonClose?.();
      onClose();
    },
    errorPopupOk: () => {
      eventMapPendingWithdrawals.errorPopupOk?.();
      onClose();
    },
    successPopupClose: () => {
      eventMapPendingWithdrawals.successPopupClose?.();
      onClose();
    },
    successPopupReload: () => {
      eventMapPendingWithdrawals.successPopupReload?.();
      onClose();
    },
    onClickCloseButton: () => {
      eventMapPendingWithdrawals.onClickCloseButton?.();
      onClose();
    },
  }), [eventMapPendingWithdrawals, onClose])

  const slotMap = useMemo(() => ({
    PendingWithdrawals: {
      component: WidgetPendingWithdrawals,
      props: {
        iconMap: iconMapPendingWithdrawals,
        contentMap: contentMapPendingWithdrawals,
        eventMap: eventMapPendingWithdrawalsPatched,
        origin: gameOptions.getOrigin(),
        token: gameOptions.getWidgetToken(),
        fetchData: fetchDataPendingWithdrawals,
      },
    },
  }), [
    contentMapPendingWithdrawals,
    eventMapPendingWithdrawalsPatched,
    fetchDataPendingWithdrawals,
    gameOptions,
    iconMapPendingWithdrawals,
  ])

  if (!gameOptions.showUserWidgets(INSUFFICIENT_BALANCE_WIDGET)) {
    return null;
  }

  const cls = cx("l6r__widget", {
    "l6r__widget--insufficient-balance": true,
  });

  return (
    <div className={cls}>
      <Widget
        iconMap={iconMap}
        contentMap={contentMap}
        eventMap={eventMap}
        slotMap={slotMap}
        classNameMap={classNameMap}
        origin={gameOptions.getOrigin()}
        token={gameOptions.getWidgetToken()}
        fetchData={fetchData}
      />
    </div>
  );
}

InsufficientBalanceWidget.propTypes = {
  gameOptions: PropTypes.object.isRequired,
  onReload: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default React.memo(InsufficientBalanceWidget, (prevProps, nextProps) => {
  return prevProps.gameOptions === nextProps.gameOptions;
});
