import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { Menu, Icon, SpinnerButton } from "../../ui";
import {
  SE_JURISDICTION,
  MT_JURISDICTION,
  DE_JURISDICTION,
  oneClickAdapter,
  Command,
} from "../../lib";
import OneClickWidget from "./widgets/OneClickWidget";
import BrandButton from "./BrandButton";
import LoginCashierButton from "./LoginCashierButton";
import HelpPhone from "./HelpPhone";
import Clock from "./Clock";
import "./MainMenu.css";

function MainMenu ({
  gameOptions,
  onBrandClick,
  onRealGameClick,
  onCashierClick,
  onCloseDialog,
  onReload,
}) {
  const { t } = useTranslation();
  const [ isOneClickWidgetVisible, setIsOneClickWidgetVisible ] = useState(false);

  const showClock = useMemo(() => {
    if (!gameOptions) {
      return null;
    }

    return [
      MT_JURISDICTION,
      SE_JURISDICTION,
      DE_JURISDICTION,
    ].includes(gameOptions.getJurisdiction());
  }, [gameOptions]);

  const showPrimaryButton = useMemo(() => {
    if (!gameOptions) {
      return null;
    }

    const { showCashier, showPlayForReal, showOneClick } = gameOptions;

    return (showPlayForReal() || (!showOneClick() && showCashier()));
  }, [gameOptions]);

  const showOneClickButton = useMemo(() => {
    if (!gameOptions) {
      return null;
    }

    const { showPlayForReal, showOneClick } = gameOptions;

    return (!showPlayForReal() && showOneClick());
  }, [gameOptions]);

  const handleOneClickShowClick = async () => {
    try {
      await oneClickAdapter.fetchOneClickData();
      setIsOneClickWidgetVisible(true);

      return true;
    } catch (e) {
      Command.openCashier();

      return false;
    }
  };

  const handleOneClickClose = () => {
    setIsOneClickWidgetVisible(false);
  };

  if (!gameOptions) {
    return null;
  }

  const cls = cx("l6r__main-menu", {
    "l6r__main-menu--one-click-open": isOneClickWidgetVisible,
  });

  return (
    <Menu className={cls}>
      {isOneClickWidgetVisible && (
        <OneClickWidget
          onClose={handleOneClickClose}
          onReload={onReload}
        />
      )}

      <Menu.Item className="l6r__menu-item--brand">
        <BrandButton
          onClick={onBrandClick}
          gameOptions={gameOptions}
        />
      </Menu.Item>

      <Menu.Group className="l6r__menu-item--button-groups">
        {showClock && (
          <Menu.Item>
            <Clock />
          </Menu.Item>
        )}

        {gameOptions.showAgeLimit() && (
          <Menu.Item>
            <div className="l6r__main-menu-age-limit">
              {gameOptions.getAgeLimit()}
            </div>
          </Menu.Item>
        )}

        {gameOptions.showHelpPhone() && (
          <Menu.Item>
            <HelpPhone phoneNumber={gameOptions.getHelpPhoneNumber()} />
          </Menu.Item>
        )}

        {showPrimaryButton && (
          <Menu.Item>
            <LoginCashierButton
              gameOptions={gameOptions}
              onRealGameClick={onRealGameClick}
              onCashierClick={onCashierClick}
            />
          </Menu.Item>
        )}

        {showOneClickButton && (
          <Menu.Item>
            <SpinnerButton
              className={"l6r__main-menu-button--primary l6r__oneclick-button"}
              color="green"
              text={t("cashier")}
              icon={Icon.Cashier}
              onClick={handleOneClickShowClick}
            />
          </Menu.Item>
        )}
      </Menu.Group>
    </Menu>
  );
}

MainMenu.propTypes = {
  gameOptions: PropTypes.object,
  onBrandClick: PropTypes.func.isRequired,
  onRealGameClick: PropTypes.func.isRequired,
  onCashierClick: PropTypes.func.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  onReload: PropTypes.func.isRequired,
};

export default MainMenu;
