import { wrapFetch } from "@cadolabs/brand-auther-provider";
import { AtlantAPIError } from "@/lib";

export async function get (url, options) {
  const response = await fetch(url, { method: "GET", ...options });

  return responseToJSON(response);
}

export async function post (path, params, namespace, version) {
  try {
    let url = null
    let options = null

    if (namespace === "project") {
      options = new OptionsRESTBuilder()
        .setBody(params)
        .build();

      const fetch = wrapFetch(window.fetch)

      const response = await fetch(path, options);
      return responseToJSON(response);
    } else {
      url = new UrlBuilder(namespace, version).build();

      options = new OptionsBuilder()
        .setBody(params)
        .setApiMethod(path)
        .build();

      const response = await fetch(url, options);
      return responseToJSON(response);
    }
  } catch (error) {
    throw new AtlantAPIError(error);
  }
}

function OptionsBuilder () {
  const method = "POST";
  let body = null;

  function setBody (value) {
    let params = {};

    if (value.entries) {
      for (const pair of value.entries()) {
        params[pair[0]] = pair[1];
      }
    } else {
      params = value;
    }

    body = {
      jsonrpc: "2.0",
      params,
    };

    return this;
  }

  function setApiMethod (value) {
    body.method = value;

    return this;
  }

  function build () {
    return {
      method,
      body: JSON.stringify(body),
    };
  }

  return {
    setBody,
    setApiMethod,
    build,
  };
}

function OptionsRESTBuilder () {
  const method = "POST";
  let body = null;

  function setBody (params) {
    body = { ...params };

    return this;
  }

  function build () {
    const res = {
      method,
      body: JSON.stringify(body),
    };

    return res;
  }

  return {
    setBody,
    build,
  };
}

function UrlBuilder (namespace = "launcher", version = "v2") {
  function build () {
    return `${window.api.backendHost}/api/${version}/${namespace}`;
  }

  return {
    build,
  };
}

function responseToJSON (response) {
  if (response.ok) {
    if (response.error) {
      throw response.error;
    }

    return response.json();
  }

  throw new Error("Something went wrong");
}
