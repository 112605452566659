import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { clickObserver, Command } from "../../lib";
import { Dialog, Icon } from "../../ui";
import "./AccountVerificationDialog.css";

function AccountVerificationDialog ({ onClose }) {
  const { t } = useTranslation();

  useEffect(() => {
    clickObserver.subscribe("verification-dialog", () => {
      onClose();
    });

    return function clean () {
      clickObserver.unsubscribe("verification-dialog");
    }
  }, [onClose]);

  const handleContinueClick = () => {
    onClose();
  };

  const handleVerifyClick = () => {
    Command.openVerifyAccount();
    onClose();
  }

  return (
    <Dialog
      className="l6r__verification-dialog"
      modal
    >
      <Dialog.Body padded opened>
        <Dialog.Header
          title={t("account_verification.title")}
          icon={Icon.Account}
        />

        <Dialog.Content opened>
          <div className="l6r__verification-text1">
            {t("account_verification.text1")}
          </div>
          <div className="l6r__verification-text2">
            {t("account_verification.text2")}
          </div>
        </Dialog.Content>

        <Dialog.Footer
          confirmLabel={t("account_verification.continueGame")}
          onConfirmClick={handleContinueClick}
          cancelLabel={t("account_verification.verifyAccount")}
          onCancelClick={handleVerifyClick}
        />
      </Dialog.Body>
    </Dialog>
  );
}

AccountVerificationDialog.propTypes = {
  onClose: PropTypes.func,
};

export default AccountVerificationDialog;
