import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Icon, DisclosureButton } from "../../ui";
import "./BrandButton.css";

function BrandButton ({ gameOptions, onClick }) {
  const brandIcon = useMemo(() => gameOptions.getBrandIcon(Icon), [ gameOptions ]);

  return (
    <DisclosureButton
      id="brand"
      className="l6r__brand-button"
      icon={brandIcon}
      onExpand={onClick}
      onCollaps={onClick}
    />
  );
}

BrandButton.propTypes = {
  gameOptions: PropTypes.object,
  onClick: PropTypes.func,
};

export default BrandButton;
