import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { wrapFetch } from "@cadolabs/brand-auther-provider";
import {
  FRANK,
  SLOTV,
  MRBIT,
  injectScript,
} from "../../../../lib";
import IconMapFrank from "@frontendwidgets/pendingwithdrawals/dist/themes/frank/iconMap";
import IconMapSlotv from "@frontendwidgets/pendingwithdrawals/dist/themes/slotv/iconMap";
import IconMapMrbit from "@frontendwidgets/pendingwithdrawals/dist/themes/mrbit/iconMap";

export function useContentMap () {
  const { t } = useTranslation();

  return useMemo(() => ({
    title: data => t("pending_withdrawals.title", data),
    titleEmpty: t("pending_withdrawals.title"),
    subtitle: t("pending_withdrawals.subtitle"),
    subtitleEmpty: t("pending_withdrawals.subtitleEmpty"),
    totalWithdrawals: t("pending_withdrawals.totalWithdrawals"),
    cancelPopupTitle: t("pending_withdrawals.cancelPopupTitle"),
    cancelPopupContent: data => t("pending_withdrawals.cancelPopupContent", data),
    successPopupTitle: data => t("pending_withdrawals.successPopupTitle", data),
    successPopupContent: t("pending_withdrawals.successPopupContent"),
    errorPopupTitle: t("pending_withdrawals.errorPopupTitle"),
    errorPopupContent: t("pending_withdrawals.errorPopupContent"),
    unlocked: t("pending_withdrawals.unlocked"),
    unlock: t("pending_withdrawals.unlock"),
    unlockAll: t("pending_withdrawals.unlockAll"),
    close: t("pending_withdrawals.close"),
    continue: t("pending_withdrawals.continue"),
    reload: t("pending_withdrawals.reload"),
    ok: t("pending_withdrawals.ok"),
  }), [t])
}

export function useEventMap ({
  locale,
  country,
  onReload,
}) {
  return useMemo(() => ({
    getWidgetSettings: () => ({
      locale,
      country,
      theme: "dark",
    }),
    successPopupReload: () => {
      onReload()
    },
  }), [country, locale, onReload])
}

export function useIconMap (brand) {
  // eslint-disable-next-line security/detect-object-injection
  return useMemo(() => ({
    [FRANK]: IconMapFrank,
    [SLOTV]: IconMapSlotv,
    [MRBIT]: IconMapMrbit,
  }[brand]), [brand])
}

export function useInjectScript (brand) {
  useEffect(() => {
    injectScript({
      type: "text/css",
      rel: "stylesheet",
      src: `/launcherassets/css/${brand}/pendingwithdrawals_210.css`,
    })
  }, [brand])
}

export const fetchData = {
  wrapper: wrapFetch,
}
