import React, { useRef, useEffect, useState, memo } from "react";
import { parseUrlQuery } from "../../lib";
import { Icon } from "../../ui";
import cx from "classnames";
import "./SwedenButtons.css";

const INTERVAL_MS = 1000

function SwedenLoginTimer () {
  const [ sessionInMs, setSessionInMs ] = useState(null);
  const timeoutRef = useRef();

  useEffect(() => {
    try {
      const query = parseUrlQuery(window.location.search);
      const sessionTime = query.get("session_time");

      if (sessionTime) {
        setSessionInMs(Date.now() - Number.parseInt(sessionTime, 10))
      }
    } catch (_) {}
  }, [])

  useEffect(() => {
    if (!sessionInMs) {
      return
    }

    const runTimer = () => {
      timeoutRef.current = setTimeout(() => {
        setSessionInMs(prevState => prevState + INTERVAL_MS);
      }, INTERVAL_MS)
    }

    runTimer();

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }
  }, [sessionInMs])

  const getFormattedTime = () => {
    let h = (Math.floor(sessionInMs / 3600000) % 24);
    let m = (Math.floor(sessionInMs / 60000) % 60);
    let s = (Math.floor(sessionInMs / 1000) % 60);

    if (h < 10) {
      h = `0${h}`;
    }

    if (m < 10) {
      m = `0${m}`;
    }

    if (s < 10) {
      s = `0${s}`;
    }

    return `${h}:${m}:${s}`;
  }

  const clsIcon = cx("l6r__link-icon", {
    "l6r__sweden-icon": true,
  });

  if (!sessionInMs) {
    return null;
  }

  return (
    <div className="l6r__sweden-link">
      <span className={clsIcon}>
        <Icon.Speltimer />
      </span>
      <span>
        {getFormattedTime()}
      </span>
    </div>
  );
}

export default memo(SwedenLoginTimer);
