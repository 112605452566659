/* eslint-disable react/prop-types */

import React, { useState, useRef, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { WAGER_PROGRESS_WIDGET } from "../../../lib";
import { wrapFetch } from "@cadolabs/brand-auther-provider";
import { Icon } from "../../../ui";
import LoadableWidget from "./LoadableWidget";
import "./WagerProgress.css";

const Widget = new LoadableWidget(() => {
  return import(/* webpackPrefetch: -3 */ "@frontendwidgets/wageringprogress");
});

function WagerProgressWidget ({ gameOptions, onLoad }) {
  const { t } = useTranslation();
  const [ isFaild, setIsFaild ] = useState(false);
  const faildCallbackRef = useRef(null);

  const fetchData = useMemo(() => ({
    wrapper: wrapFetch,
  }), []);

  useEffect(() => {
    faildCallbackRef.current = () => {
      setIsFaild(true);
    };

    return () => {
      faildCallbackRef.current = null;
    }
  }, [])

  const getIconMap = () => ({
    LogoIcon: ({ className }) => (
      <div className={className}>
        <Icon.WagerProgress />
      </div>
    ),
  });

  const getEventMap = () => {
    return ({
      whenSuccessWageringDataLoaded (_) {
        onLoad();
      },
      whenFailWageringDataLoaded (_) {
        if (faildCallbackRef.current) {
          faildCallbackRef.current()
        }
        onLoad();
      },
    });
  };

  const getContentMap = () => {
    return ({
      title: t("wagering_progress.title"),
      subtitle: t("wagering_progress.wager"),
    });
  };

  const getFetchData = () => {
    return fetchData;
  };

  if (!gameOptions.showUserWidgets(WAGER_PROGRESS_WIDGET)) {
    return null;
  }

  if (isFaild) {
    return null;
  }

  const cls = cx("l6r__widget", {
    "l6r__widget--wager-progress": true,
  });

  return (
    <div className={cls}>
      <Widget
        iconMap={getIconMap()}
        eventMap={getEventMap()}
        contentMap={getContentMap()}
        origin={gameOptions.getOrigin()}
        token={gameOptions.getWidgetToken()}
        fetchData={getFetchData()}
      />
    </div>
  );
}

WagerProgressWidget.propTypes = {
  gameOptions: PropTypes.object.isRequired,
  onLoad: PropTypes.func.isRequired,
};

export default React.memo(WagerProgressWidget, (prevProps, nextProps) => {
  return prevProps.gameOptions === nextProps.gameOptions;
});
