import React from "react";
import PropTypes from "prop-types";
import { consoleLog } from "../../lib";

class ErrorBoundary extends React.Component {
  static getDerivedStateFromError (error) {
    consoleLog(error.toString());
    return { hasError: true };
  }

  state = { hasError: false };

  render () {
    if (this.state.hasError) {
      return null;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default ErrorBoundary;
