import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { wrapFetch } from "@cadolabs/brand-auther-provider";
import {
  FRANK,
  SLOTV,
  MRBIT,
  injectScript,
} from "../../../../lib";
import IconMapFrank from "@frontendwidgets/insufficientbalance/dist/themes/frank/iconMap";
import IconMapSlotv from "@frontendwidgets/insufficientbalance/dist/themes/slotv/iconMap";
import IconMapMrbit from "@frontendwidgets/insufficientbalance/dist/themes/mrbit/iconMap";

export function useContentMap ({
  spinsCount,
  currency,
  rightBalance,
}) {
  const { t } = useTranslation()

  return useMemo(() => ({
    depositPopupTitle: t("insufficient_balance.depositPopupTitle"),
    depositPopupSubtitle: t("insufficient_balance.depositPopupSubtitle", { count: spinsCount }),
    depositPopupContent: t("insufficient_balance.depositPopupContent"),
    depositPopupBalanceTitle: t("insufficient_balance.depositPopupBalanceTitle"),
    depositPopupBalanceContent: t("insufficient_balance.depositPopupBalanceContent", {
      currency,
      balance: rightBalance,
    }),
    depositPopupMainButton: t("insufficient_balance.depositPopupMainButton"),
  }), [currency, rightBalance, spinsCount, t])
}

export function useEventMap ({
  handleCashierClick,
  onClose,
}) {
  return useMemo(() => ({
    onDepositPopupMainButton: () => {
      handleCashierClick();
      onClose();
    },
    onDepositPopupCloseButton: () => {
      onClose();
    },
    onOrdersPopupCloseButton: () => {
      onClose();
    },
  }), [handleCashierClick, onClose])
}

export function useIconMap (brand) {
  // eslint-disable-next-line security/detect-object-injection
  return useMemo(() => ({
    [FRANK]: IconMapFrank,
    [SLOTV]: IconMapSlotv,
    [MRBIT]: IconMapMrbit,
  }[brand]), [brand])
}

export function useInjectScript (brand) {
  useEffect(() => {
    injectScript({
      type: "text/css",
      rel: "stylesheet",
      src: `/launcherassets/css/${brand}/insufficientbalance_210.css`,
    })
  }, [brand])
}

export const classNameMap = {
  mainContainer: "insufficient-balance-main-container",
}

export const fetchData = {
  wrapper: wrapFetch,
}
