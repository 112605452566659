import React from "react";
import PropTypes from "prop-types";
import { Link, Icon } from "../../ui";
import { useTranslation } from "react-i18next";
import "./PanicButton.css";

function PanicButton ({ onPanicClick }) {
  const { t } = useTranslation();

  return (
    <Link
      className="l6r__panic-link"
      iconClassName="l6r__panic-icon"
      icon={Icon.QuickLock}
      text={t("quick_lock")}
      onClick={onPanicClick}
      clickDelay={2_500}
    />
  );
}

PanicButton.propTypes = {
  onPanicClick: PropTypes.func.isRequired,
};

export default PanicButton;
