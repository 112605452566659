import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link, Icon } from "../../ui";
import { fullscreenAdapter } from "../../lib";

function FullscreenButton ({ onClick }) {
  const { t } = useTranslation();

  const getIcon = () => {
    const { ExitFullscreen, Fullscreen } = Icon;

    return isFullscreen() ? ExitFullscreen : Fullscreen;
  };

  const getDictionaryKey = () => {
    return isFullscreen() ? "exit_fullscreen" : "fullscreen";
  };

  const isFullscreen = () => {
    return fullscreenAdapter.isFullscreen();
  };

  return (
    <Link
      className="l6r__btn-fullscreen"
      title={t(getDictionaryKey())}
      text={t(getDictionaryKey())}
      icon={getIcon()}
      onClick={onClick}
      small
    />
  );
}

FullscreenButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default FullscreenButton;
