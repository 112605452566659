import React from "react";
import PropTypes from "prop-types";
import SwedenButtons from "../common/SwedenButtons";
import PanicButton from "../common/PanicButton";
import "./Header.css";

function Header ({
  gameOptions,
  onProfileClick,
  onBlockYourselfClick,
}) {
  if (
    !gameOptions ||
    (!gameOptions.showSwedenButtons() && !gameOptions.showPanicButton())
  ) {
    return null;
  }

  return (
    <div className="l6r__header">
      {gameOptions.showSwedenButtons() && (
        <div className="l6r__section--sweden">
          <SwedenButtons
            onProfileClick={onProfileClick}
            onBlockYourselfClick={onBlockYourselfClick}
          />
        </div>
      )}

      {gameOptions.showPanicButton() && (
        <div className="l6r__section--de">
          <PanicButton onPanicClick={onBlockYourselfClick} />
        </div>
      )}
    </div>
  );
}

Header.propTypes = {
  gameOptions: PropTypes.shape({
    showSwedenButtons: PropTypes.func,
    showPanicButton: PropTypes.func,
  }),
  onProfileClick: PropTypes.func.isRequired,
  onBlockYourselfClick: PropTypes.func.isRequired,
};

export default Header;
