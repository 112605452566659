import React from "react";
import PropTypes from "prop-types";
import { Link, Icon } from "../../ui";
import SwedenLoginTimer from "./SwedenLoginTimer";
import "./SwedenButtons.css";

function SwedenButtons ({ onProfileClick, onBlockYourselfClick }) {
  return (
    <div className="l6r__sweden-buttons-group">
      <Link
        target="_blank"
        className="l6r__sweden-link"
        icon={Icon.Spelpaus}
        iconClassName="l6r__sweden-icon"
        text="Spelpaus"
        href="https://www.spelpaus.se"
      />

      <Link
        target="_blank"
        className="l6r__sweden-link"
        icon={Icon.Sjalvtest}
        iconClassName="l6r__sweden-icon"
        text="Självtest"
        href="https://www.stodlinjen.se/#!/spelberoende-test-pgsi"
      />

      <Link
        text="Spelgränser"
        className="l6r__sweden-link"
        icon={Icon.Spelgranser}
        iconClassName="l6r__sweden-icon"
        onClick={onProfileClick}
      />

      <Link
        text="Spelstopp"
        className="l6r__sweden-link"
        icon={Icon.Spelstopp}
        iconClassName="l6r__sweden-icon"
        onClick={onBlockYourselfClick}
      />

      <SwedenLoginTimer />
    </div>
  );
}

SwedenButtons.propTypes = {
  onProfileClick: PropTypes.func.isRequired,
  onBlockYourselfClick: PropTypes.func.isRequired,
};

export default SwedenButtons;
