import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import bg from "./locales/bg.json";
import bs from "./locales/bs.json";
import de from "./locales/de.json";
import en from "./locales/en.json";
import hi from "./locales/hi.json";
import hr from "./locales/hr.json";
import ja from "./locales/ja.json";
import nl from "./locales/nl.json";
import pt from "./locales/pt.json";
import ro from "./locales/ro.json";
import ru from "./locales/ru.json";
import sr from "./locales/sr.json";
import sv from "./locales/sv.json";

i18n
  .use(initReactI18next)
  .init({
    debug: false,
    compatibilityJSON: "v4",
    resources: {
      bg,
      bs,
      de,
      en,
      hi,
      hr,
      ja,
      nl,
      pt,
      ro,
      ru,
      sr,
      sv,
    },
    fallbackLng: "en",
    ns: ["default"],
    defaultNS: "default",
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
