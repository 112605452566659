import React, { useState, useEffect, useRef } from "react";
import "./Clock.css";

const formatValue = value => {
  return Number.parseInt(value, 10) < 10 ? `0${value}` : value;
};

function Clock () {
  const [ formattedTime, setFormattedTime ] = useState("");
  const requestRef = useRef();

  useEffect(() => {
    function tick () {
      const date = new Date();
      const h = date.getHours();
      const m = date.getMinutes();

      const time = `${formatValue(h)}:${formatValue(m)}`;
      setFormattedTime(time);

      requestRef.current = requestAnimationFrame(tick);
    }

    requestRef.current = requestAnimationFrame(tick);

    return () => cancelAnimationFrame(requestRef.current);
  }, []);

  return (
    <div className="l6r__clock">
      {formattedTime}
    </div>
  );
}

export default Clock;
