import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { clickObserver } from "../../lib";
import { Link, Icon, Popup } from "../../ui";
import "./HelpPhone.css";

function HelpPhone ({ phoneNumber }) {
  const [ show, setShow ] = useState(false);

  useEffect(() => {
    if (show) {
      clickObserver.subscribe("help_phone", () => {
        setShow(false);
      });
    } else {
      clickObserver.unsubscribe("help_phone");
    }

    return function clean () {
      clickObserver.unsubscribe("help_phone");
    }
  }, [show]);

  const handleClick = e => {
    e.preventDefault();
    setShow(prevState => !prevState);
  };

  return (
    <div className="l6r__phone-button" onClick={handleClick}>
      {show && (
        <Popup text={phoneNumber} />
      )}

      <Link
        className="l6r__main-menu-button--secondary"
        icon={Icon.Phone}
        fill
      />
    </div>
  );
}

HelpPhone.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
};

export default HelpPhone;
