/* eslint-disable react/prop-types */

import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  RECENT_GAMES_WIDGET,
  DEMO_MODE,
  REAL_MODE,
  Command,
  windowMessageObserver,
  parseUrlQuery,
} from "../../../lib";
import { useTranslation } from "react-i18next";
import GameCard, { CardUsagePlace } from "@crossplatformwidgets/game-card"
import cx from "classnames";
import iconMapLib from "@crossplatformwidgets/game-card/build/themes/frank/iconMap"
import imageMapLib from "@crossplatformwidgets/game-card/build/themes/frank/imageMap"
import variableMapLib from "@crossplatformwidgets/game-card/build/themes/frank/variableMap"
import { getGamesByCategory, fetchVwoSession } from "../../../api";
import "./RecentGames.css";

function RecentGamesWidget ({ gameOptions }) {
  const { t } = useTranslation();
  const [ recentGames, setRecentGames ] = useState(null);
  const [ recommendedGames, setRecommendedGames ] = useState(null);
  const tokenRef = useRef(null)

  useEffect(() => {
    const handlePostMessage = async data => {
      if (tokenRef.current) {
        return
      }

      if (data && data.type === "bauther:SEND_TOKEN" && !tokenRef.current) {
        tokenRef.current = data;

        if (tokenRef.current) {
          loadRecentGames()
          loadRecommendedGames()
        }
      }
    }

    if (gameOptions.showUserWidgets(RECENT_GAMES_WIDGET)) {
      windowMessageObserver.subscribe("recentGames", handlePostMessage);
      Command.getBAuthToken();
    }

    return () => {
      windowMessageObserver.unsubscribe("recentGames");
    }
  }, [])

  const loadRecentGames = async () => {
    try {
      const { payload } = await getGamesByCategory("recent", gameOptions, tokenRef.current)
      setRecentGames(payload.items)
    } catch (_) {
      setRecentGames([])
    }
  }

  const loadRecommendedGames = async () => {
    try {
      const { payload } = await getGamesByCategory("recommended", gameOptions, tokenRef.current)
      setRecommendedGames(payload.items)
    } catch (_) {
      setRecommendedGames([])
    }
  }

  const getItems = () => {
    if (recentGames && recentGames.length > 0) {
      return recentGames
    }

    if (recommendedGames && recommendedGames.length > 0) {
      return recommendedGames
    }

    return new Array(5).fill(null);
  }

  const getApiHost = () => {
    const domain = new URL(gameOptions.getLobbyUrl());

    return `https://${domain.host}`
  }

  const getTitle = () => {
    if (recentGames && recentGames.length > 0) {
      return t("game_categories.recent");
    }

    if (recommendedGames && recommendedGames.length > 0) {
      return t("game_categories.recommended");
    }

    return "";
  }

  const handleClick = (game, mode) => () => {
    try {
      const query = parseUrlQuery(window.location.search);

      query.append("campaign", "ABT-273")
      query.append("goal", "recent_game_launched")
      query.append("type", "activate")

      fetchVwoSession(query)
    } catch (e) {
      console.log(e)
    }

    Command.openGame({ game, mode })
  }

  const cls = cx("l6r__widget", {
    "l6r__widget--recent-games": true,
  });

  const clsTitle = cx("l6r__widget--recent-games-title", {
    "l6r__widget--recent-games-title-skeleton": !getTitle(),
  });

  if (!gameOptions.showUserWidgets(RECENT_GAMES_WIDGET)) {
    return null;
  }

  return (
    <div className={cls}>
      <div className={clsTitle}>
        {getTitle()}
      </div>

      <div className="l6r__widget--recent-games-list">
        {getItems().map((item, index) => (
          <div key={index} className="l6r__widget--recent-games-item">
            {item && (
              <GameCard
                game={item}
                eventMap={{
                  onPlay: handleClick(item, REAL_MODE),
                  onDemo: handleClick(item, DEMO_MODE),
                }}
                iconMap={iconMapLib}
                imageMap={imageMapLib}
                variableMap={variableMapLib}
                contentMap={{
                  playButtonText: t("recent_games.play"),
                  demoButtonText: t("recent_games.demo"),
                  newLabel: t("recent_games.labels.new"),
                  exclusiveLabel: t("recent_games.labels.exclusive"),
                }}
                usagePlace={CardUsagePlace.RECENT}
                cardHeight={100}
                currency={gameOptions.getCurrency()}
                locale={gameOptions.getLocale()}
                jurisdiction={gameOptions.getJurisdiction()}
                apiHost={getApiHost()}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

RecentGamesWidget.propTypes = {
  gameOptions: PropTypes.object.isRequired,
};

export default RecentGamesWidget;
