import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Dialog, Icon } from "../../ui";
import {
  Command,
  BLOCK_YOURSELF_COMMAND,
  LOGOUT_COMMAND,
  OPEN_CASHBOX_COMMAND,
  OPEN_LOBBY_COMMAND,
  OPEN_PROFILE_COMMAND,
  OPEN_SUPPORT_COMMAND,
  PLAY_FOR_REAL_COMMAND,
  RELOAD_COMMAND,
  MESSAGE_KIND_ERROR,
  MESSAGE_KIND_WARNING,
} from "../../lib";

function ErrorMessageDialog ({ onClose, onReload, buttons, text, onClick, kind }) {
  const { t } = useTranslation();

  const handleConfirmClick = () => {
    if (onClick) {
      onClick();
    }

    onClose();
  };

  const getText = () => {
    return (text || t("game_is_unavailable"));
  };

  const getIcon = () => {
    if (kind === MESSAGE_KIND_ERROR) {
      return Icon.Error;
    }

    return Icon.Warning;
  };

  const getButtons = () => {
    return buttons.reduce((acc, item) => {
      const action = {
        label: item.title,
        onClick: () => {
          switch (item.action) {
            case OPEN_LOBBY_COMMAND: {
              return Command.openLobby();
            }
            case OPEN_PROFILE_COMMAND: {
              return Command.openProfile();
            }
            case OPEN_CASHBOX_COMMAND: {
              Command.openCashier();
              return onClose();
            }
            case OPEN_SUPPORT_COMMAND: {
              Command.openSupport();
              return onClose();
            }
            case BLOCK_YOURSELF_COMMAND: {
              return Command.blockYourself();
            }
            case LOGOUT_COMMAND: {
              return Command.logout();
            }
            case PLAY_FOR_REAL_COMMAND: {
              return Command.playForReal();
            }
            case RELOAD_COMMAND: {
              onReload();
              return onClose();
            }
            default: {
              onClose();
            }
          }
        },
      };

      acc.push(action);
      return acc;
    }, []);
  };

  const getProps = () => {
    const props = {
      title: getText(),
      icon: getIcon(),
    };

    if (buttons) {
      props.buttons = getButtons();
    } else {
      props.confirmLabel = t("home");
      props.onConfirmClick = handleConfirmClick;
    }

    return props;
  }

  return (
    <Dialog
      {...getProps()}
      modal
    />
  );
}

ErrorMessageDialog.propTypes = {
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  onReload: PropTypes.func,
  buttons: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    action: PropTypes.string,
  })),
  text: PropTypes.string,
  kind: PropTypes.oneOf([MESSAGE_KIND_ERROR, MESSAGE_KIND_WARNING]),
};

export default ErrorMessageDialog;
