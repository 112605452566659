import React from "react";
import PropTypes from "prop-types";
import LimitsSection from "./LimitsSection";
import "./Limits.css";

function Limits ({ limits }) {
  return (
    <div className="l6r__limits">
      {[...limits.values()].map(section => (
        <LimitsSection
          key={section.title}
          title={section.title}
          items={section.items}
        />
      ))}
    </div>
  );
}

Limits.propTypes = {
  limits: PropTypes.instanceOf(Map).isRequired,
};

export default Limits;
