import React from "react";
import { createRoot } from "react-dom/client";
import { initSentry, consoleLog, setApiHosts } from "./lib";
import App from "./App";
import "./fonts/Roboto/Roboto-Regular.ttf";
import "./fonts/Roboto/Roboto-Bold.ttf";
import "./fonts/Roboto/Roboto-Medium.ttf";
import "./index.css";

setApiHosts();
initSentry("launcher");
consoleLog();

const container = document.getElementById("root");
const root = createRoot(container);

root.render(<App />);
