import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "./LicenseCaption.css";

function LicenseCaption ({ licenseNumber }) {
  const { t } = useTranslation();

  return (
    <div className="l6r__license">
      {t("license_number", { number: licenseNumber })}
    </div>
  );
}

LicenseCaption.propTypes = {
  licenseNumber: PropTypes.string.isRequired,
};

export default LicenseCaption;
