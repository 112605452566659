import React, { useState, useEffect, useRef } from "react";
import {
  ACCOUNT_VERIFICATION_DIALOG_TYPE,
  ERROR_MESSAGE_DIALOG_TYPE,
  LOW_BALANCE_DIALOG_TYPE,
  MENU_DIALOG_TYPE,
  NOTIFICATION_MESSAGE_DIALOG_TYPE,
  ORIENTATION_CHANGED_DIALOG_TYPE,
  USER_LIMITS_DIALOG_TYPE,
  WARNING_MESSAGE_DIALOG_TYPE,
  dialogObserver,
} from "../lib";
import AccountVerificationDialog from "../components/dialogs/AccountVerificationDialog";
import ErrorMessageDialog from "../components/dialogs/ErrorMessageDialog";
import InsufficientBalanceWidget from "../components/common/widgets/InsufficientBalance";
import LimitsDialog from "../components/dialogs/LimitsDialog";
import MenuDialog from "../components/dialogs/MenuDialog";
import NotificationMessageDialog from "../components/dialogs/NotificationMessageDialog";
import OrientationWasChangedDialog from "../components/dialogs/OrientationWasChangedDialog";

export default function useDialog ({ onReload, handleCashierClick }) {
  const [ dialogType, setDialogType ] = useState(null);
  const messageParams = useRef(null);
  const activeDialog = useRef(null);

  useEffect(() => {
    const handleDialogFire = (value, params) => {
      if (
        messageParams.current &&
        messageParams.current.beforeClose
      ) {
        messageParams.current.beforeClose();
      }

      messageParams.current = params;

      setDialogType(prevState => {
        activeDialog.current = prevState === value ? null : value;
        return activeDialog.current;
      });
    };

    dialogObserver.subscribe("dialog", handleDialogFire);

    return function clean () {
      dialogObserver.unsubscribe("dialog");
    };
  }, []);

  const handleClose = senderDialogType => {
    if (senderDialogType && senderDialogType !== activeDialog.current) {
      return;
    }

    activeDialog.current = null;
    setDialogType(null);
  };

  function currentDialog () {
    switch (dialogType) {
      case ACCOUNT_VERIFICATION_DIALOG_TYPE:
        return (
          <AccountVerificationDialog
            onClose={handleClose}
          />
        );
      case ORIENTATION_CHANGED_DIALOG_TYPE:
        return (
          <OrientationWasChangedDialog
            onClose={handleClose}
          />
        );
      case ERROR_MESSAGE_DIALOG_TYPE:
      case WARNING_MESSAGE_DIALOG_TYPE:
        return (
          <ErrorMessageDialog
            onClose={handleClose}
            onReload={onReload}
            {...messageParams.current}
          />
        );
      case MENU_DIALOG_TYPE:
        return (
          <MenuDialog
            onClose={handleClose}
            {...messageParams.current}
          />
        );
      case NOTIFICATION_MESSAGE_DIALOG_TYPE:
        return (
          <NotificationMessageDialog
            onClose={handleClose}
            {...messageParams.current}
          />
        );
      case USER_LIMITS_DIALOG_TYPE:
        return (
          <LimitsDialog
            onClose={handleClose}
            {...messageParams.current}
          />
        );
      case LOW_BALANCE_DIALOG_TYPE:
        return (
          <InsufficientBalanceWidget
            onClose={handleClose}
            onReload={onReload}
            handleCashierClick={handleCashierClick}
            {...messageParams.current}
          />
        );
      default:
        return null;
    }
  }

  function renderDialog () {
    if (!dialogType) {
      return null;
    }

    return currentDialog();
  }

  return { renderDialog };
}
