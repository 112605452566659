import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { CircularProgress } from "../../../ui"
import { roundToNextTen as round } from "../../../lib";
import "./LimitsItem.css";

function LimitsItem ({ item }) {
  const { t } = useTranslation();

  const getTitle = () => {
    const { period, type } = item;

    const key = (
      period &&
      period.length > 0 &&
      ["day", "week", "month"].includes(period)
    ) ? period : type;

    return t(`limits.${key}`);
  };

  const getDuration = () => {
    const { time_till: till } = item;

    if (!till) {
      return "";
    }

    const date = till
      .replace(/\d+:\d+:\d+/, "")
      .trim();

    return `${t("limits.till")} ${date}`;
  };

  const getCurrentValue = () => {
    const { current_value: currentValue } = item;

    return formatValue(currentValue);
  };

  const getTotal = () => {
    const { value } = item;

    return `${t("limits.of")} ${formatValue(value)}`;
  };

  const getProgress = () => {
    const { current_value: value, value: total } = item;

    return Math.ceil((value / total) * 100);
  };

  const formatValue = value => {
    const { value_type: valueType, currency } = item;

    switch (valueType) {
      case "money": {
        return `${value} ${currency}`;
      }
      default: {
        return `${value} ${t("limits.hrs")}`;
      }
    }
  };

  const valueCls = cx("l6r__limits-title", {
    [`l6r__limits-title--color-${round(getProgress())}`]: item,
  });

  return (
    <div className="l6r__limits-item">
      <div className="l6r__limits-item__col">
        <div className="l6r__limits-title">
          {getTitle()}
        </div>

        <div className="l6r__limits-subtitle">
          {getDuration()}
        </div>
      </div>

      <div className="l6r__limits-item__col l6r__limits-item__col--align-right">
        <div className={valueCls}>
          {getCurrentValue()}
        </div>

        <div className="l6r__limits-subtitle">
          {getTotal()}
        </div>
      </div>

      <div className="l6r__limits-item__col">
        <CircularProgress value={getProgress()} />
      </div>
    </div>
  );
}

LimitsItem.propTypes = {
  item: PropTypes.shape({
    type: PropTypes.string,
    period: PropTypes.string,
    time_till: PropTypes.string,
    current_value: PropTypes.number,
    value: PropTypes.number,
    value_type: PropTypes.string,
    currency: PropTypes.string,
  }).isRequired,
};

export default LimitsItem;
