import React, { Suspense } from "react";

const LoadableWidget = loader => {
  const LazyLoadedWidget = React.lazy(() => {
    return import(/* webpackPrefetch: -1 */ "@frontendwidgets/vendors")
      .then(() => loader());
  });

  return props => (
    <Suspense fallback="...">
      <LazyLoadedWidget {...props} />
    </Suspense>
  );
};

export default LoadableWidget;
