import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import LimitsItem from "./LimitsItem";
import "./LimitsSection.css";

function LimitsSection ({ title, items }) {
  const { t } = useTranslation();

  const cls = cx("l6r__limits-section", {
    [`l6r__limits-section-${title}`]: title,
  });

  return (
    <div className={cls}>
      <div className="l6r__limits-title">
        {t(title)}
      </div>

      {items.map(item => (
        <LimitsItem
          key={`${title}-${item.period}`}
          item={item}
        />
      ))}
    </div>
  );
}

LimitsSection.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
};

export default LimitsSection;
